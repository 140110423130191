<template>
  <div>
    <v-row class="app-po-view">
      <v-col
        cols="12"
        md="9"
      >
        <p-o-edit
            ref="poForm"
            :poData="poData"
            :projectData="project"
          >
        </p-o-edit>
      </v-col>

      <!-- Right Column: PO Action -->
      <v-col
        cols="12"
        md="3"
      >
        <v-card class="mb-6">
          <v-card-text>
            <v-btn
                color="secondary"
                class="mb-3"
                block
                dark
                @click.native="clickBack"
                >
                    <v-icon
                        left
                        dark
                        class="me-2"
                    >
                        {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Back</span>
                </v-btn>
            <v-btn
              class="mb-3"
              color="warning"
              block
              @click="createPO"
            >
              Create
            </v-btn>
          </v-card-text>
        </v-card>
        <v-select
            v-model="poData.payment_terms"
            outlined
            :loading="paymentTermsLoading"
            :items="paymentTermsOptions"
            item-text="label"
            item-value="value"
            dense
            placeholder="Payment Terms"
            label="Payment Terms"
            :menu-props="{ offsetY: true }"
            hide-details="auto"
            class="mb-3"
        ></v-select>
        <v-select
            v-model="poData.status"
            outlined
            :items="statusOptions"
            item-text="label"
            item-value="value"
            dense
            placeholder="PO Status"
            label="PO Status"
            :menu-props="{ offsetY: true }"
            hide-details="auto"
            class="mb-3"
        ></v-select>
        <v-text-field
            v-model="poData.down_payment"
            outlined
            dense
            placeholder="Down Payment"
            label="Down Payment"
            hide-details="auto"
            class="mb-3"
        ></v-text-field>
        
        <div class="d-flex align-center justify-space-between mb-6">
            <span>Home Country / No Billing needed</span>
            <v-switch
                v-model="poData.home_country"
                hide-details="auto"
                class="mt-0"
                :input-value="true"
            ></v-switch>
        </div>

      </v-col>
    </v-row>
    
    <v-snackbar
      v-model="isSnackbarVisibility"
      timeout="2000">
        PO not saved. Please fill out the required fields.
        <template v-slot:action="{ attrs }">
          <v-btn
            color="error"
            v-bind="attrs"
            text
            @click="isSnackbarVisibility = false"
          >
            Close
          </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onUnmounted, onMounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import poStoreModule from '../poStoreModule'
import POEdit from '../po-edit/POEdit.vue'
import usePO from '../usePO'
import { mdiSendOutline, mdiChevronLeft } from '@mdi/js'

export default {
  components: {
    POEdit,
  },
  props: {
    projectData: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const isSnackbarVisibility = ref(false)

      // ————————————————————————————————————
      //* ——— Store Registration
      // ————————————————————————————————————
  
      const PO_APP_STORE_MODULE_NAME = 'app-po'
  
      // Register module
      if (!store.hasModule(PO_APP_STORE_MODULE_NAME)) {
          store.registerModule(PO_APP_STORE_MODULE_NAME, poStoreModule)
      }
  
      // UnRegister on leave
      onUnmounted(() => {
          if (store.hasModule(PO_APP_STORE_MODULE_NAME)) store.unregisterModule(PO_APP_STORE_MODULE_NAME)
      })
    
    const localProjectData = ref({})
    localProjectData.value = JSON.parse(JSON.stringify(props.projectData))

    const poData = ref({})

    const {
      paymentTermsLoading,
      paymentTermsOptions,
      statusOptions,
      project,
      addPO,
      fetchProject,
      fetchPaymentTerms
    } = usePO()


    const resetPOData = () => {
      poData.value = {
        created_at: new Date().toISOString().slice(0,10),
        service_date: '',
        contact: {},
        supplier: '',
        supplier_contact: '',
        currency: {id:49},
        project: localProjectData.value,
        home_country:false,
        status: 'DRAFT',
        down_payment: 0,
        payment_terms: 2,
        po_items: [
          {
            order_item: '',
            qty: 0,
            unit_price: 0,
            description: '',
          },
        ]
      }
    }
    
    const poForm = ref(null)
    
    const isPOFormValid = () => {
      
      const isFormValid = vm.$refs.poForm.$refs.refPurchaseOrderForm.validate()
      if (!isFormValid) isSnackbarVisibility .value = true
      else isSnackbarVisibility .value = false
      return isFormValid
    }

    const createPO = () => {
      const isValid = isPOFormValid()
      if (isValid){
        const tempLocal = JSON.parse(JSON.stringify(poData.value))
        const poLocal = {
          ...tempLocal,
          currency: tempLocal.currency.id,
          contact: tempLocal.contact,
          service_date: tempLocal.service_date,
          po_items: tempLocal.po_items,
          project: tempLocal.project.job_number,
          supplier_contact: tempLocal.supplier_contact,
          supplier: tempLocal.supplier.id,
          office: tempLocal.project.billed_by.id,
          home_country:tempLocal.home_country,
          status: tempLocal.status,
          down_payment: tempLocal.down_payment,
          payment_terms: tempLocal.payment_terms,
        }
        addPO({
          data:poLocal,
          project: JSON.parse(JSON.stringify(project.value))
        })
      }
    }

    
    const clickBack = () => {
        const currentRoute = router.currentRoute.name
        if (currentRoute == 'po-add') return router.push({ name: 'po' })
        if (currentRoute == 'project-view-po-add') return router.push({ name: 'project-view-po', params: { id: router.currentRoute.params.id } })
        return router.push({ name: 'po' })
    }

    onMounted(() => {
      fetchPaymentTerms()
      if(router.currentRoute.name === 'project-view-po-add') {
        fetchProject(router.currentRoute.params.id)
      }
      resetPOData()
    })

    return {
      paymentTermsLoading,
      paymentTermsOptions,
      statusOptions,
      clickBack,
      isSnackbarVisibility,
      poForm,
      poData,
      createPO,
      project,
      // Icons
      icons: {
        mdiSendOutline,
        mdiChevronLeft
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/po.scss';
</style>
